.GifPickerReact button.gpr-btn-clear-search {
	position: absolute;
	right: var(--gpr-search-bar-inner-padding);
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: end;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
	border-radius: 50%;
}

.GifPickerReact .gpr-icn-clear-search {
	background-image: svg-load(./svg/times.svg);
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: 20px;
	height: 20px;
	width: 20px;
}


.GifPickerReact button.gpr-btn-clear-search:hover .gpr-icn-clear-search,
.GifPickerReact .gpr-search-container button.gpr-btn-clear-search:focus .gpr-icn-clear-search {
	background-position-y: -20px;
}

.GifPickerReact button.gpr-btn-clear-search:hover,
.GifPickerReact button.gpr-btn-clear-search:focus {
	background: var(--gpr-hover-bg-color);
}

.GifPickerReact.gpr-dark-theme button.gpr-btn-clear-search .gpr-icn-clear-search {
	background-position-y: -40px;
}

.GifPickerReact.gpr-dark-theme button.gpr-btn-clear-search:hover .gpr-icn-clear-search {
	background-position-y: -60px;
}