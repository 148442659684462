//
// _authentication.scss
//

.auth-card {
  .auth-effect-main {
    @media (min-width: 576px) {
      width: 444px;
      height: 444px;
      border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

      .effect-circle-1 {
        width: 396px;
        height: 396px;
        border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

        .effect-circle-2 {
          width: 348px;
          height: 348px;
          border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

          .effect-circle-3 {
            width: 300px;
            height: 300px;
            border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);
          }
        }
      }
    }

    .auth-user-list {
      li {
        position: absolute;

        &:nth-child(1) {
          bottom: 110px;
          left: 30px;
        }

        &:nth-child(2) {
          top: 95px;
          left: 66px;
        }

        &:nth-child(3) {
          top: 20px;
          right: 98px;
        }

        &:nth-child(4) {
          bottom: -15px;
          right: 235px;
        }

        &:nth-child(5) {
          bottom: 220px;
          right: 35px;
        }

        &:nth-child(6) {
          bottom: 60px;
          right: 60px;
        }
      }
    }
  }
}

.signin-other-title {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    border-top: 1px dashed var(--#{$prefix}border-color);
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 2;
    background-color: var(--#{$prefix}secondary-bg);
    padding: 2px 16px;
  }
}

// auth-pass-inputgroup
.auth-pass-inputgroup {
  input[type="text"] + .btn .ri-eye-fill {
    &:before {
      content: "\ecb6";
    }
  }
}

//passowrd validations
#password-contain {
  display: none;

  p {
    padding-left: 13px;

    &.valid {
      color: $success;

      &::before {
        position: relative;
        left: -8px;
        content: "✔";
      }
    }

    &.invalid {
      color: $danger;

      &::before {
        position: relative;
        left: -8px;
        content: "✖";
      }
    }
  }
}

.password-addon {
  z-index: 5 !important;
}
