.GifPickerReact .gpr-gif-list {
    padding: var(--gpr-body-padding);
    display: flex;
    flex-direction: row;
    gap: var(--gpr-category-list-padding);
    flex: 1;
    overflow-y: scroll;
}

.GifPickerReact .gpr-gif-list-column {
    flex: 1;
    display: grid;
    grid-gap: var(--gpr-category-list-padding);
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-template-rows: auto 1fr;
}

.GifPickerReact .gpr-gif-list-no-result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-bottom: 30%;
}
