.GifPickerReact .gpr-result-image {
    border-radius: var(--gpr-category-border-radius);
    transition: box-shadow ease-in-out 0.15s;
    cursor: pointer;
}

.GifPickerReact .gpr-result-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--gpr-category-border-radius);
    background-color: var(--gpr-placeholder-color);
}

.GifPickerReact .gpr-result-image:hover {
    box-shadow: 0 0 0 2px var(--gpr-category-border-color-hover);
}