//
// _profile.scss
//

.profile-media {
  .fixed-width {
    height: 185px;
  }
}

.profile-user {
  position: relative;
  display: inline-block;

  .profile-photo-edit {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    cursor: pointer;
  }

  .profile-photo-remove {
    position: absolute;
    right: -5px;
    left: auto;
    top: 5px;
    height: 19px;
    cursor: pointer;
  }

  .user-profile-image {
    object-fit: cover;
  }

  .profile-img-file-input {
    display: none;
  }
}
