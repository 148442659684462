.tags-input .react-tagsinput-tag {
  background-color: var(--#{$prefix}primary-bg-subtle);
  color: var(--#{$prefix}primary);
  border: 1px solid var(--#{$prefix}primary-bg-subtle);
}

.tags-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.details-tag {
  background-color: var(--#{$prefix}primary-bg-subtle);
  border: 1px solid var(--#{$prefix}primary-bg-subtle);
  color: var(--#{$prefix}primary);
  font-size: 12px;
  border-radius: 4px;
  padding: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.tags-wrapper {
  border: 1px solid;
  padding: 6px 6px 2px 6px;
  border-radius: var(--tb-border-radius) !important;
  border: var(--tb-border-width) var(--tb-border-style) var(--tb-border-color) !important;
}
