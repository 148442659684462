//
// _dropdown.scss
//

.dropdown-toggle::after {
  // content: "";
  // position: absolute;
  // line-height: 15px;
  // // content: "\F0140";
  // font-family: "Material Design Icons";
  // display: inline-block;
  // margin-left: .255em;
  font-size: var(--#{$prefix}font-md);
  display: inline-block;
  margin-left: 0.255em;
  line-height: 15px;
  content: "\f0140";
  font-family: "Material Design Icons";
}

.dropdown-menu {
  box-shadow: $box-shadow-lg;
  position: absolute;
  z-index: 1000;

  &.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
  }
}

@media (min-width: 600px) {
  .dropdown-menu-xl {
    width: 420px;
  }

  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }

  .filterDropDown {
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu-md {
      width: 400px;
    }
  }

  .filterDropDown2 {
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu-md {
      // max-width: 600px !important;
      // width: 100% !important;
      width: 600px;
    }
  }
}

@media (max-width: 600px) {
  .filterDropDown {
    &.dropdown {
      position: static;

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-menu {
        width: 100% !important;
        top: 2px !important;
        transform: translate3d(0px, 41px, 0px) !important;
      }
    }
  }
}

.dropdown-toggle-split {
  border-left: none;

  &::after {
    margin-left: 0px;
  }

  &:before {
    content: "";
    position: absolute;
    background-color: rgba($white, 0.12);
    top: calc(#{$btn-border-width} * -1);
    bottom: calc(#{$btn-border-width} * -1);
    right: calc(#{$btn-border-width} * -1);
    left: 0;
    border-radius: 0 $btn-border-radius $btn-border-radius 0;
  }
}

// Dropdown Mega Menu
.dropdown-mega {
  position: static !important;
}

// Dropdown size
.dropdown-mega-menu-xl {
  width: 38rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

[dir="ltr"] {
  .dropdown-menu-start {
    --tb-position: end;
  }

  .dropdown-menu-end {
    --tb-position: start;
  }
}

// Custom Dropdown with Tabs
.dropdown-head {
  .nav-tabs-custom {
    border: 0;

    .nav-link {
      color: var(--#{$prefix}secondary-color);

      &.active {
        background-color: var(--#{$prefix}dropdown-bg);
      }

      &:hover {
        color: var(--#{$prefix}dark);
      }
    }
  }
}

// card color variant
@each $state in map-keys($theme-colors) {
  .dropdownmenu-#{$state} {
    .dropdown-item {
      &:is(:focus, :hover, .active) {
        background-color: var(--#{$prefix}#{$state}-bg-subtle);
        color: var(--#{$prefix}#{$state});
      }
    }
  }
}

//arrow-none

.arrow-none {
  &::after {
    display: none;
  }
}
