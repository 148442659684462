.GifPickerReact {
    color-scheme: light;

    --gpr-highlight-color: #007aeb;
    --gpr-hover-bg-color: #f1f8ff;
    --gpr-focus-bg-color: #e0f0ff;
    --gpr-text-color: #858585;
    --gpr-search-input-bg-color: #f6f6f6;
    --gpr-picker-border-color: #e7e7e7;
    --gpr-bg-color: #fff;
    --gpr-horizontal-padding: 10px;
    --gpr-picker-border-radius: 8px;

    /* Header */
    --gpr-header-padding: 15px var(--gpr-horizontal-padding);

    /* Search */
    --gpr-search-input-bg-color-active: var(--gpr-search-input-bg-color);
    --gpr-search-input-padding: 0 32px;
    --gpr-search-input-border-radius: 8px;
    --gpr-search-input-height: 40px;
    --gpr-search-input-text-color: var(--gpr-text-color);
    --gpr-search-input-placeholder-color: var(--gpr-text-color);
    --gpr-search-bar-inner-padding: var(--gpr-horizontal-padding);
    --gpr-search-border-color-active: var(--gpr-highlight-color);

    /* Body */
    --gpr-body-padding: var(--gpr-header-padding) 0px;
    --gpr-category-list-padding: var(--gpr-horizontal-padding);
    --gpr-category-border-radius: 4px;
    --gpr-category-background-opacity: 40%;
    --gpr-category-font-color: var(--gpr-picker-border-color);
    --gpr-category-font-size: 16px;
    --gpr-category-font-weight: 600;
    --gpr-category-shadow-opacity: 60%;
    --gpr-category-shadow-opacity-hover: 65%;
    --gpr-category-border-color-hover: var(--gpr-highlight-color);

    /* Placeholder */
    --gpr-placeholder-color: #d3d3d3;
}

.GifPickerReact.gpr-dark-theme {
    color-scheme: dark;
    --gpr-dark: #000;
    --gpr-hover-bg-color: #363636f6;
    --gpr-focus-bg-color: #474747;
    --gpr-text-color: #c0c0c0;
    --gpr-search-input-bg-color: #3b3b3b;
    --gpr-picker-border-color: #2b2b2b;
    --gpr-bg-color: #222222;
    --gpr-category-font-color: #e7e7e7;
    --gpr-search-input-bg-color-active: var(--gpr-dark);
    --gpr-placeholder-color: var(--gpr-search-input-bg-color);
}

.GifPickerReact * {
    box-sizing: border-box;
}

.GifPickerReact.gpr-main {
    border-color: var(--gpr-picker-border-color);
    border-radius: var(--gpr-picker-border-radius);
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--gpr-bg-color);
    color: var(--gpr-text-color);
}

.GifPickerReact button.gpr-btn {
    cursor: pointer;
    border: 0;
    background: none;
    outline: none;
    padding: 0;
}
  