//
// _timeline.scss
//

//activity-timeline
.acitivity-timeline {
  position: relative;
  overflow: hidden;

  .acitivity-item {
    position: relative;

    .flex-shrink-0 {
      z-index: 2;
    }

    .acitivity-avatar {
      background-color: var(--#{$prefix}secondary-bg);
      border: 3px solid var(--#{$prefix}secondary-bg);
      height: 32px;
      width: 32px;
    }

    &:before {
      content: "";
      position: absolute;
      border-left: 1px dashed var(--#{$prefix}border-color);
      left: 16px;
      height: 100%;
      top: 5px;
      z-index: 0;
    }

    &:last-child {
      &::before {
        border-color: transparent;
      }
    }
  }
}

.acitivity-timeline-2 {
  border-radius: 12px;
  position: relative;

  li {
    padding-bottom: 1.5rem;
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;

    &:last-child {
      border: 0px;
      padding-bottom: 0;
    }

    &:before {
      content: "";
      width: 15px;
      height: 15px;
      background: var(--#{$prefix}secondary-bg);
      border: 1px solid var(--#{$prefix}secondary);
      box-shadow: 3px 3px 0px lighten($secondary, 30%);
      box-shadow: 3px 3px 0px lighten($secondary, 30%);
      border-radius: 50%;
      position: absolute;
      left: -8px;
      top: 0px;
    }
  }
}
